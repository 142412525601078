import { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_PRODUCT_QUANTITY, CHECKOUT, LS_CART_NAME, RESTORE_CART } from "./constants"
import { syncCartStateWithLocalStorage } from "./reducers"
export const addToCart = (item) => ({
  type: ADD_TO_CART, item
})
export const removeFromCart = (item) => ({
  type: REMOVE_FROM_CART, item
})
export const updateProductQuantity = (item) => ({
  type: UPDATE_PRODUCT_QUANTITY, item
})
export const checkout = (item) => ({
  type: CHECKOUT, item
})
export const restoreCart = (item) => ({
  type: RESTORE_CART, item
})

// Non Store Actions
export const getCartFromToken = (token) => {
  return syncCartStateWithLocalStorage({}, 'get', `${LS_CART_NAME}_${token}`)
}
export const removeCartFromToken = (token) => {
  return syncCartStateWithLocalStorage({}, 'remove', `${LS_CART_NAME}_${token}`)
}
export const setCart = (cart, name = '') => {
  return syncCartStateWithLocalStorage({}, 'set', name, cart)
}