import React from 'react';
import { get, findIndex } from 'lodash'
import Img from 'gatsby-image'
import styles from './cart.scss';
import { Link, useStaticQuery, graphql } from 'gatsby';
import NumberSpinner from '../number-spinner/number-spinner'
import { productCategoryUrl, castToInternalShopifyId, findAncestorByClassNameInEventPath, 
  decorateWithCookies, triggerEvent, queryParamsFromString, getGAClientId, isAndroid } from '../../helpers'
import  Cookies from 'js-cookie'
import { FaTimes } from 'react-icons/fa'
import Spinner from 'react-bootstrap/Spinner'
import Toast from 'react-bootstrap/Toast'
// import CartLoader from './cart-loader'
import { BsLockFill, BsTrash } from "react-icons/bs";
import ShoppingBag from '../../../static/img/icons/shopping-bag.svg';

// https://github.com/muffinresearch/payment-icons
import visa from 'payment-icons/min/single/visa.svg';
import mastercard from 'payment-icons/min/single/mastercard.svg';
import discover from 'payment-icons/min/single/discover.svg';
import amex from '../../../static/img/icons/amex.svg';
import shopifyBadge from '../../../static/img/icons/shopify-secure-badge-white.svg'

import { connect } from 'react-redux'
import { removeFromCart, updateProductQuantity, checkout as storeCheckout } from './actions'

import { store } from '../../redux/redux-wrapper'
import { SALE_TYPE_SUBSCRIPTION } from "../../constants"
import { UTM_PROP_NAME, UTM_FA_PROP_NAME, COUPON_PROP_NAME, EMAIL_PROP_NAME } from "./constants"

import loadable from '@loadable/component'

const CartLoader = loadable(() => (import('./cart-loader')));

import { CartPointsWidget } from '../loyaltylion/loyaltylion'

import axios from 'axios'

class Cart extends React.Component {
  constructor(props) {
    super(props)
    
    this.toggleCart = this.toggleCart.bind(this)
    this.toggleCartCB = this.toggleCartCB.bind(this)
    this.openCart = this.openCart.bind(this)
    this.closeCart = this.closeCart.bind(this)
    this.initCartContainer = this.initCartContainer.bind(this)
    this.updateItemLineQtyOnSpinnerUpdate = this.updateItemLineQtyOnSpinnerUpdate.bind(this)
    this.removeItemFromCart = this.removeItemFromCart.bind(this)
    this.calcCartTotal = this.calcCartTotal.bind(this)
    this.calcCartTotalSavings = this.calcCartTotalSavings.bind(this)
    this.checkoutCart = this.checkoutCart.bind(this)
    this.showCouponForm = this.showCouponForm.bind(this)
    this.storeUTMParams = this.storeUTMParams.bind(this)
    this.storeCouponCode = this.storeCouponCode.bind(this)
    this.storeCustomerEmail = this.storeCustomerEmail.bind(this)
    // this.storeShowToastInfo = this.storeShowToastInfo.bind(this)
    this.clearUTMParams = this.clearUTMParams.bind(this)
    this.clearCouponCode = this.clearCouponCode.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    
    this.freeShippingFrom = get(this, 'props.data.site.siteMetadata.freeShipping', 0) || 0
    
    const cartOpen = (this.props.location && 'cart_open' in queryParamsFromString(this.props.location.search))  ? true : false
    
    this.state = {
      isOpen: cartOpen,
      itemsLines: props.itemsLines,
      cartXPos: cartOpen ? 0 : '-100%',
      isCheckingOut: false,
      showCouponForm: false,
      cartTotal: this.calcCartTotal(),
      cartTotalSavings: this.calcCartTotalSavings(),
      amountToFreeShipping: this.freeShippingFrom,
      showCouponToast: false,
      // showPromoToast: false,
      initialized: cartOpen
    };
  }
  
  componentDidMount() {
    this.unsubscribeFromStore = store.subscribe(this.openCart)
    //Build shopify client and checkout

    document.addEventListener('click', this.handleDocumentClick);
    
    this.storeUTMParams()
    this.storeCouponCode()
    this.storeCustomerEmail()
    // this.storeShowToastInfo()
  }
  
  componentWillUnmount() {
    if (this.unsubscribeFromStore) {
      this.unsubscribeFromStore()
    }
    document.removeEventListener('click', this.handleDocumentClick);
  }
  
  componentDidUpdate() {
    const total = this.calcCartTotal()
    const totalSavings = this.calcCartTotalSavings()
    const amountToFreeShipping = this.freeShippingFrom - total
    if (total !== this.state.cartTotal || amountToFreeShipping !== this.state.amountToFreeShipping) {
      this.setState({
        cartTotal: total,
        cartTotalSavings: totalSavings,
        amountToFreeShipping: amountToFreeShipping
      })
    }
  }

  // storeShowToastInfo() {
  //   let dismissWelcomeMessage = localStorage.dismissWelcomeMessage
  //   if (!dismissWelcomeMessage) {
  //     this.setState({
  //       showPromoToast: true
  //     })
  //     localStorage.dismissWelcomeMessage = true
  //   }
  // }
  
  /**
   * Handles click event so we can close the cart when it a click is made outside it
   * @param  {event} e
   * @return {void}
   */
  handleDocumentClick(e) {
    // These are all the classes that will prevent this from happening
    if (!findAncestorByClassNameInEventPath(e, ['cart-icon', 'cart-sidebar', 'btn-add-to-cart', 'btn-shop']) && this.state.isOpen) {
      this.toggleCart()
    }
  }
  
  /**
   * Stores UTM Params if exists
   * This method will take care of creating cookies if necessary. There will be 2 cookies.
   * 1 - will contian the last attribution information. It will last 7 days on the browser and will be overriden each time users come with new params on the querystring
   * 2 - will contin the first attribution information. This cookie will last 1 year and won't change once created
   * For legacy, we wil create cookie #2 from #1 if #2 doesn't exist and #1 does.
   * @return {void}
   */
  storeUTMParams() {    
    let utmParams = {
      utm_referrer: document.referrer
    }
    const searchQuery = get(this, 'props.location.search')
    
    /* Cookies Lifetime in days */
    const cookieLifetime = 7
    const cookieFaLifetime = 365    
    
    if (searchQuery) {
      const queryParams = queryParamsFromString(searchQuery)
      if (queryParams) {
        for (let i in queryParams) {
          if (i.indexOf('utm_') === 0) {
            utmParams[i] = queryParams[i]
          }
        }
      }
    }
    
    /** Last Attribution Cookie #1 **/
    const utmParamsCk = Cookies.get(UTM_PROP_NAME)
    
    /** First Attribution Cookie #2 **/
    const utmParamsFaCk = Cookies.get(UTM_FA_PROP_NAME)
    
    /** Due to `utm_referrer` it will always be one by default, so this will only happen when there are more than 1**/
    const areNewParams = Object.keys(utmParams).length > 1 
    
    const stringifiedUTMParams = JSON.stringify(utmParams)
    const cookieOptions = {
      domain: this.props.location.host.replace('www', '').replace(/:.+/, '')
    }
    
    if (!utmParamsCk || areNewParams) {
      // This means we obtained new params from the url (or no params but we have no cookie so we want to store the referrer), so we want to override what already exists or create a new cookie if there is no cookie already
      // We will always store the last attribution cookie (#1) in this case
      Cookies.set(UTM_PROP_NAME, stringifiedUTMParams, { ...cookieOptions, expires: cookieLifetime })
      this.utmParams = utmParams
    } else if (utmParamsCk) {
      // If there already exists a cookie or we don't have new params to override it, we update current params with it
      this.utmParams = JSON.parse(utmParamsCk)
    }
    
    const utmParamsFaCkObject = utmParamsFaCk && JSON.parse(utmParamsFaCk)
    if ((!utmParamsFaCk && this.utmParams) || (Object.keys(utmParamsFaCkObject).length === 1 && 'utm_referrer_firsttouch' in utmParamsFaCkObject)) {
      // If there is no first attribution cookie and we have params, then we create it (#2)
      // if there is first attribution cookie but it only contains one param (and it's the referrer one)
      const faUtmParams = {}
      Object.keys(this.utmParams).forEach((key) => {
        faUtmParams[`${key}_firsttouch`] = this.utmParams[key]
      })
      const stringifiedFaUtmParams = JSON.stringify(faUtmParams)
      Cookies.set(UTM_FA_PROP_NAME, stringifiedFaUtmParams, { ...cookieOptions, expires: cookieFaLifetime })
      this.utmFAParams = faUtmParams
    } else if (utmParamsFaCk) {
      // If there already exists a cookie or we don't have new params to override it, we update current params with it
      this.utmFAParams = utmParamsFaCkObject
    }
    
    this.utmParams = {
      ...this.utmParams,
      ...this.utmFAParams
    }
  }
  
  /**
   * Cleares utm params if they exist
   * @return {void}
   */
  clearUTMParams() {
    if ('localStorage' in window) {
      return Cookies.remove(UTM_PROP_NAME)
    }
  }
  
  /**
   * Stores coupon code if exists
   * @return {void}
   */
  storeCouponCode() {    
    let couponCode = null
    const searchQuery = get(this, 'props.location.search')
    if (searchQuery) {
      const queryParams = queryParamsFromString(searchQuery)
      if (queryParams && queryParams[COUPON_PROP_NAME]) {
        couponCode = queryParams[COUPON_PROP_NAME]
        this.setState({
          showCouponToast: true
        })
      }
    }
    if ('localStorage' in window) {
      let couponLS = localStorage.getItem(COUPON_PROP_NAME)
      if (couponCode) {
        this.couponCode = couponCode
        localStorage.setItem(COUPON_PROP_NAME, couponCode)
      } else if (couponLS) {
        this.couponCode = couponLS
      }
    }
  }
  
  /**
   * Cleares coupon code if exists
   * @return {void}
   */
  clearCouponCode() {
    if ('localStorage' in window) {
      return localStorage.removeItem(COUPON_PROP_NAME)
    }
  }

  /**
   * Stores customer email if exists
   * @return {void}
   */
  storeCustomerEmail() {
    let customerEmail = null
    const searchQuery = get(this, 'props.location.search')
    if (searchQuery) {
      const queryParams = queryParamsFromString(searchQuery)
      if (queryParams && queryParams[EMAIL_PROP_NAME]) {
        customerEmail = queryParams[EMAIL_PROP_NAME]
      }
    }
    if ('localStorage' in window) {
      let emailLS = localStorage.getItem(EMAIL_PROP_NAME)
      if (!emailLS && customerEmail) {
        this.customerEmail = customerEmail
        localStorage.setItem(EMAIL_PROP_NAME, customerEmail)
      } else if (emailLS) {
        this.customerEmail = emailLS
      }
    }
  }

  /**
   * Cleares customer email if exists
   * @return {void}
   */
  clearCustomerEmail() {
    if ('localStorage' in window) {
      return localStorage.removeItem(EMAIL_PROP_NAME)
    }
  }
  
  /**
   * Initializes Cart and sets a reference to it
   * @param  {Object} ref
   * @return {void}
   */
  initCartContainer(ref) {
    if (ref) {      
      const container = ref.parentNode
      container.removeChild(ref)
      //We remove it from its original placement and place it on the body (for positioning purposes)
      this.cartContainer = ref
      document.body.appendChild(ref)
      this.cartXPos = `-${this.cartContainer.offsetWidth}px`
      if (this.state.cartXPos !== 0) {
        // Means this is open by default
        this.setState({
          cartXPos: this.cartXPos
        })
      }
    }
  }

  /**
   * Toggles cart status (and initializes it if neccessary)
   * @return {void}
   */
  toggleCart(e) {
    if (e && e.stopPropagation && e.preventDefault) {
      e.stopPropagation()
      e.preventDefault()
    }
    
    if (!this.state.initialized) {
      this.setState({
        initialized: true
      }, this.toggleCartCB)
    } else {
      this.toggleCartCB()
    }
  };
  
  /**
   * This is what will actually toggle the cart
   * @return {void}
   */
  toggleCartCB() {
    this.setState({ 
      isOpen: !this.state.isOpen,
    }, () => {
      if (this.state.isOpen) {
        window.scrollTo(0, 0)
        this.setState({
          cartXPos: 0
          // cartXPos: `-${this.rightPos0}px`
        })
        triggerEvent('cart_open', {})
        
        // This is for accesibility 
        // this.cartCloseButtonRef.focus()
      } else {
        this.setState({
          cartXPos: this.cartXPos
        })
      }
    });
  }
  
  
  /**
   * Opens cart (uses toggleCart only when it's closed)
   * @return {void}
   */
  openCart() {
    if (!this.state.isOpen) {
      this.toggleCart()
    }
  }
  
  /**
   * Closes cart (uses toggleCart only when it's closed)
   * @return {void}
   */
  closeCart() {
    if (this.state.isOpen) {
      this.toggleCart()
    }
  }
  
  /**
   * Updates line's qty when it's changed using the spinner
   * @param  {Number} lineIndex
   * @param  {Number} qty
   * @return {void}
   */
  updateItemLineQtyOnSpinnerUpdate(line, qty) {
    this.props.dispatch(updateProductQuantity({
      productId: line.product.id,
      [SALE_TYPE_SUBSCRIPTION]: line.product[SALE_TYPE_SUBSCRIPTION],
      qty
    }))
  }

  removeItemFromCart(line) {
    this.props.dispatch(removeFromCart({
      productId: line.product.id,
      [SALE_TYPE_SUBSCRIPTION]: line.product[SALE_TYPE_SUBSCRIPTION]
    }))
  }
  
  /**
   * Calculates cart's total
   * @return {String}
   */
  calcCartTotal() {
    let total = 0
    if (this.props.itemsLines) {
      this.props.itemsLines.forEach((item, index) => {
        total += (item.product.price * item.qty)
      })
    }
    
    return total
  }

  /**
   * Calculates cart's total savings
   * @return {String}
   */
  calcCartTotalSavings() {
    let total = 0
    if (this.props.itemsLines) {
      this.props.itemsLines.forEach((item, index) => {
        if (item.product.subscription){
          total += (item.product.price * item.qty / 0.85) - (item.product.price * item.qty)
        }
      })
    }

    return total.toFixed(2)
  }

  /**
   * Checks if there is a subscription item as part of the cart
   * This function is not part of the reducers but makes sense to add it here so we centralize everything here
   * @param  {array}  itemsLines
   * @return {Boolean}
   */
  hasSubscriptionItems(itemsLines) {
    return findIndex(itemsLines, item => item[SALE_TYPE_SUBSCRIPTION]) >= 0
  }
  /**
   * Checks cart out
   * @return {void}
   */
  checkoutCart() {
    this.setState({
      isCheckingOut: true
    }, () => {
      let checkoutPromise = null
      
      let method = 'post'
      let url = '/.netlify/functions/recharge-checkout?'
      if (this.props.cartToken) {
        method = 'put'
        url = `${url}&token=${this.props.cartToken}`
      }
      
      // We are going to delete UTM params them after successful checkout
      // this.clearUTMParams()
      this.clearCouponCode()
      this.clearCustomerEmail()

      checkoutPromise = axios[method](url, {
        line_items: this.props.itemsLines.map((item) => {
          return { 
            quantity: item.qty,
            title: item.product.title,
            product_id: castToInternalShopifyId(item.product.product_id.replace(/Shopify__[a-zA-Z]+__/, '')),
            variant_id: castToInternalShopifyId(item.product.id.replace(/Shopify__[a-zA-Z]+__/, '')),
            price: item.product.price,
            is_subscription: item[SALE_TYPE_SUBSCRIPTION]
          }
        }),
        utm_params: this.utmParams || null,
        coupon_code: this.couponCode || null,
        email: this.customerEmail || null,
        _ga: getGAClientId()
      }).then( res => res.data)
      // }
      
      if (checkoutPromise) {
        checkoutPromise.then((checkout) => {                
          const checkoutUrl = `${decorateWithCookies(checkout.webUrl)}&site_ref=${encodeURIComponent(location.origin)}`    
          
          triggerEvent('checkout_initiated', {
            cart: {
              url: checkoutUrl,
              token: checkout.token,
              line_items: this.props.itemsLines,
            }
          })
            
          this.props.dispatch(storeCheckout(checkout))
          
          if ('history' in window) {
            let search = this.props.location.search || '?'
            if (search.indexOf('cart_open') === -1) {
              search += '&cart_open=1'
            }
            history.pushState(null, document.title, this.props.location.href.replace(/\??.+$/, search));
          }
          
          /** We will hide the loader after 1 second (this is a workaround for the issue we are experiencing when going back in android devices) **/
          if (isAndroid()) {
            setTimeout(() => { this.setState({isCheckingOut: false}) }, 1000 )
          }
          
          window.location.href = checkoutUrl
        })
      }
    })
  }
  
  /**
   * Shows coupon form
   * @return {void}
   */
  showCouponForm(e) {
    e.preventDefault()
    this.setState({
      showCouponForm: true
    })
  }


  render() {
    const itemsLines = this.props.itemsLines
    const idMeLogo = this.props.data.idMeLogo
    return (
      <>
        <a href="#" className="cart-sidebar-icon" onClick={this.toggleCart}>
          <img src={ShoppingBag} alt="shopping cart"/>
          { itemsLines.length ? (<span className='badge badge-danger'>{ itemsLines.length < 10 ? itemsLines.length : '9+' }</span>) : '' }
          {/* <FaShoppingBag /> */}
        </a>
        { this.state.initialized && <div className={`cart cart-sidebar ${this.state.isOpen ? 'open' : ''}`} style={{
              right: this.state.cartXPos
              }} ref={this.initCartContainer}>
          <CartLoader style={ {display: this.state.isCheckingOut ? 'block' : 'none'} } />
          <div className='cart-header bg-light'>
            <h3>Shopping Cart</h3>    
            <button  onClick={this.toggleCart} className="btn btn-transparent btn-close"  title="Close Cart"  ref={(ref) => {this.cartCloseButtonRef = ref}}> 
              <FaTimes title="Close Cart"/>              
            </button>      
          </div>
          <div className='cart-body'>
            { itemsLines.length ? (
              <ul className='product-lines'>
                { itemsLines.map((line, index) => {
                  const _this = this
                  let imgAttrs = {
                    className: 'img-fluid product-image'
                  }
                  if (line.product.image instanceof Object === true) {
                    if (line.product.image.fluid) {
                      imgAttrs.fluid = line.product.image.fluid
                    } else if (line.product.image.fixed) {
                      imgAttrs.fixed = line.product.image.fixed
                    }
                  } else {
                    imgAttrs.src = line.product.image
                  }
                  return (
                    <li className='product-line row' key={ index } tabIndex="0">
                      <div className='col-4 p-0'>
                        { line.product.image instanceof Object === true ? (
                          <Img { ...imgAttrs } />
                        ) : (
                          <img { ...imgAttrs } />
                        ) }
                      </div>
                      <div className='col-8 p-0'>
                        <div className="d-flex ml-1">
                          <h4 className='product-title col-8 col-md-9 p-0 font-weight-bold'>{line.product.title}</h4>
                          <button className="btn btn-link col-4 col-md-3 p-0 remove-cart" onClick={() => { this.removeItemFromCart(line) }} title="Remove Item From Cart">delete <BsTrash title="Trash Icon"/></button>
                        </div>
                        <div className='d-flex justify-content-between align-items-center ml-1'>
                          <div className="col-12 p-0">
                            <div className="d-flex">
                              {(line.product.subscription) ? ( <div className="purchase-type col-8 p-0">Auto-Refill</div> ) : ( <div className="purchase-type col-8 p-0">One-time Purchase</div> )}
                              {(line.product.subscription) ? ( <div className="straight-price col-4 p-0 text-right"><s>$ {(line.product.price * line.qty / 0.85).toFixed(2)}</s></div> ) : ""}
                            </div>
                            <div className="d-flex justify-content-between align-items-end my-2">
                              <div className=" col-4 col-md-5 p-0 your-price-text">Your Price</div>
                              <div className='product-price col-8 col-md-7 p-0 d-flex flex-row-reverse align-items-center'>
                                ${ (line.product.price * line.qty).toFixed(2) }
                                {(line.product.subscription) ? ( <div className="savings">15% Savings&nbsp;&nbsp;</div> ) : ""}
                              </div>
                            </div>
                          <NumberSpinner ref={ (ref) => { if (ref) ref.setState({value: line.qty})  } } 
                            onUpdate={(value) => {_this.updateItemLineQtyOnSpinnerUpdate(line, value) }} 
                            initValue={line.qty} 
                            min={0} />
                          </div>

                        </div>
                      </div>
                    </li>
                  )
                }) }
                {/* Code below will be applied on V2  */}
                {/* <li className='product-coupon product-line row'>
                  <a href='#' className={`${this.state.showCouponForm ? 'd-none' : ''}`} onClick={ this.showCouponForm }>Apply a Coupon Code +</a>
                  <div className={`${this.state.showCouponForm ? '' : 'd-none'} col-8 col-lg-7 input-group`}>
                    <input type='text' clasName='form-control' placeholder='ENTER CODE' />
                  </div>
                  <div className={`${this.state.showCouponForm ? '' : 'd-none'} col-4 col-lg-5`}>
                    <button className='btn btn-secondary btn-block px-0'>Apply</button>
                  </div>
                </li> */}
                <li className='product-total-line row font-18 mt-3 d-flex justify-content-between' tabIndex="0">
                  <CartPointsWidget cartPrice={ this.state.cartTotal * 100 } className="bg-light" />
                </li>
                <li className='product-total-line row font-18 mt-3 d-flex justify-content-between' tabIndex="0">
                  Subtotal ({ itemsLines.length > 1 ? itemsLines.length + " Items" : '1 Item' }):<span className="product-total-inner text-right">${ this.state.cartTotal.toFixed(2) }</span>
                </li>
                <li className='product-total-line row font-18 d-flex justify-content-between'>
                  Shipping:<span className="product-total-inner text-right">--</span>
                </li>
                {(this.state.cartTotalSavings > 0) && (
                  <li className='product-total-line font-weight-700 text-primary row font-18 d-flex justify-content-between' tabIndex="0">
                    Savings:<span className="product-total-inner text-right">${this.state.cartTotalSavings}</span>
                  </li>
                )}
                <li className='product-total-line row product-free-shipping pb-3' tabIndex="0">
                  {
                    this.state.amountToFreeShipping > 0 ?
                      `You are $${this.state.amountToFreeShipping.toFixed(2)} from FREE SHIPPING!` :
                      `Yay! Your order qualifies for FREE SHIPPING`
                  }
                </li>
                {(this.couponCode) && (
                  <li className='product-total-line row font-18 product-discount-code mt-3 pb-3 d-block' tabIndex="0">
                    { (this.hasSubscriptionItems(itemsLines)) ?
                      <>
                      Additional Discount Applied At Checkout: <div className="text-primary font-weight-700">{this.couponCode}</div>
                      </>
                     :
                      <>
                      Discount Code Applied At Checkout: <div className="text-primary font-weight-700">{this.couponCode}</div>
                      </>
                      }
                  </li>
                )}
                <li className='product-total-line row font-18 font-weight-700 mt-3 d-flex justify-content-between' tabIndex="0">
                  Total:<span className="product-total-inner text-right">${ this.state.cartTotal.toFixed(2) }</span>
                </li>
                <li className='product-total-line row my-2 text-left font-16' tabIndex="0">
                  <i>Shipping & Taxes calculated at checkout</i>
                </li>
                <li className='product-total-line row payment-methods mt-3 pb-3 text-left'>
                  <img src={discover} className="payment-icon img-fluid" alt="discover" />
                  <img src={visa} className="payment-icon img-fluid" alt="visa" />
                  <img src={mastercard} className="payment-icon img-fluid" alt="mastercard" />
                  <img src={amex} className="payment-icon img-fluid" alt="amex" />
                  <img src={shopifyBadge} className="payment-icon img-fluid" alt="shopify-secure" />
                </li>
              </ul>
            ) : (
              <p className="m-0">
                Your cart is empty!
                <br />
              </p>
            ) }
          </div>
          <div className='cart-footer'>
            { itemsLines.length ? (
              <>
                <button onClick={ this.checkoutCart } className='btn btn-success btn-block' disabled={ this.state.isCheckingOut }>{ this.state.isCheckingOut ? (
                  <Spinner animation="border" role="status" size='sm'><span className="sr-only">Loading...</span></Spinner>
                ) : `Proceed to checkout` }</button>
                {/* { this.state.isCheckingOut ? (
                  <div className="mt-3"><i>We are now redirecting you to a secure checkout...</i></div>
                ) : ''} */}
                {/*<button className='btn btn-outline-dark btn-block mt-4' onClick={this.toggleCart}>Continue Shopping</button>*/}
              </>
            ) : (
              <Link to={ productCategoryUrl() } onClick={ this.closeCart } className='btn btn-secondary btn-block'>Shop Now</Link>
            )}
            <p className='text-small mt-3 mb-0'>secure and encrypted transactions <BsLockFill title="Lock Icon"/></p>
          </div>
          <div className='cart-footer-guarantee text-center font-18'>
            <p className='font-weight-bold mb-1'>
              30-Day Money Back Guarantee
            </p>
            <p className='text-small'>
              If you are not satisfied with your product, we refund you. No if’s, but but’s, no disappointed pups.
            </p>
          </div>
          {/* <div className='cart-footer bg-gray-light-medium'>
            <Img fluid={ idMeLogo.childImageSharp.fluid } className='img-fluid idme-logo' />
            <p className='text-small mt-2'>
              Military members receive 15% off with ID.me
            </p>
          </div> */}
          {/*<div className='cart-footer bg-gray-light-medium border-top'>*/}
            {/*<p className='font-weight-bold'>*/}
              {/*30-Day Money Back Guarantee*/}
            {/*</p>*/}
            {/*<p className='text-small'>*/}
              {/*If you are not satisfied with your product, we do have a 30 day money back guarantee!*/}
            {/*</p>*/}
          {/*</div>*/}
        </div> }
        { this.state.showCouponToast && (
          <div className="cart-toast-coupon-container">
            <Toast id="cart-toast-coupon" onClose={() => this.setState({showCouponToast: false})} show={ this.state.showCouponToast }>
              <Toast.Header>
                <span className="mr-auto">Hi pet parent &#128075;</span>
              </Toast.Header>
              <Toast.Body>
                <span className="font-16">Your code: <span className="coupon">{ this.couponCode }</span><br/>
                 will be automatically applied at checkout. Happy shopping!</span>
              </Toast.Body>
            </Toast>
          </div>
        ) }

        {/* START OF TOAST FOR SPECIFIC PROMO - (COMMENT THIS SECTION WHEN THE PROMO ENDS)*/}
        {/*{this.state.showPromoToast && !this.state.showCouponToast && (*/}
          {/*<div className="promo-toast-container">*/}
            {/*<Toast id="promo-toast" onClose={() => this.setState({showPromoToast: false})} show={this.state.showPromoToast}>*/}
              {/*<Toast.Header>*/}
                {/*<span className="mr-auto">Hi pet parent &#128075;</span>*/}
              {/*</Toast.Header>*/}
              {/*<Toast.Body>*/}
                {/*<span className="font-16">free gift auto applied for orders over $75 at check out</span>*/}
              {/*</Toast.Body>*/}
            {/*</Toast>*/}
          {/*</div>*/}
        {/*) }*/}
        {/* END OF TOAST FOR SPECIFIC PROMO */}

      </>
    )
  }
}

const CartComp = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query CartQuery {
      site {
        siteMetadata {
          freeShipping
        }
      }      
      idMeLogo: file(relativePath: { eq: "idme-button.png" }) {
        childImageSharp {
          fluid(maxHeight: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }     
    }
  `)
  
  return (
    <Cart  {...props} data={data} ref={ref} />
  )
});

export default connect(state => {
  return {
    itemsLines: state.cart.itemsLines,
    cartToken: state.cart.token
  }
}, null)(CartComp)