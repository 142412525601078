import React from 'react'
// import { Link } from 'gatsby'
import styles from './loyaltylion.scss'
import { refreshWidgets } from './functions'
import { IoMdInformationCircleOutline } from "react-icons/io";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

class LLWidget extends React.Component {
  constructor(props) {
    super(props)
    this.tooltipTarget = React.createRef();
    this.state = {
      show: false
    }
  }
  
  componentDidMount() {
    // This component needs LoyaltyLion to be previously initialized (components/theme/layout.js does it)
    refreshWidgets()
  }
  
  componentDidUpdate() {
    // This component needs LoyaltyLion to be previously initialized (components/theme/layout.js does it)
    refreshWidgets()
  }
}


class ProductPointsWidget extends LLWidget {  
  render() {
    const { product } = this.props
    
    // console.log(product);
    
    return (
      <>
        <div className={`loyaltylion-container loyaltylion-product-points ${this.props.className}`}  ref={this.tooltipTarget} >
          <i className="ico-reward"></i>
          <span className="points-container">
            <span style={{display: 'none'}} 
              data-lion-price-for-product-id={ product.id }>
                { product.price }
            </span>
            <span data-lion-points-for-product-id={ product.id }></span>
          </span>
          <IoMdInformationCircleOutline onClick={() => this.setState({show: !this.state.show})} title="Show Details"/>
        </div>
        <Overlay target={this.tooltipTarget.current} show={this.state.show} placement="top" rootClose={true} onHide={() => this.setState({show: false})}>
          <Tooltip>
            Earn points to redeem for exclusive Honest Paws perks and rewards. <a target="_blank" href='/flea-market' className="text-white"><u>Learn more</u></a>
          </Tooltip>
        </Overlay>
      </>
    )
  }
}

class CartPointsWidget extends LLWidget {  
  render() {
    const { cartPrice } = this.props
    
    // console.log(product);
    
    return (
      <>
        <div className={`loyaltylion-container loyaltylion-cart-points ${this.props.className}`}  ref={this.tooltipTarget} >
          <i className="ico-reward"></i>
          <span className="points-container">
            If you proceed with checkout. 
            <br/>
            You will earn  <span data-lion-points-for={ cartPrice }></span> points.           
          </span>
          <IoMdInformationCircleOutline onClick={() => this.setState({show: !this.state.show})} title="Show Details"/>
        </div>
        <Overlay target={this.tooltipTarget.current} show={this.state.show} placement="top" rootClose={true} onHide={() => this.setState({show: false})}>
          <Tooltip>
            Your points can be redeemed for coupons,  products, special deals and much more!
          </Tooltip>
        </Overlay>
      </>
    )
  }
}

export {
  ProductPointsWidget,
  CartPointsWidget
}
