import React from 'react'
import { Link } from 'gatsby'
import { pick } from 'lodash'
import styles from './number-spinner.scss'


class NumberSpinner extends React.Component {
  constructor(props) {
    super(props)   
    this.state = {
      value: this.props.initValue || this.props.min
    }
    this.up = this.up.bind(this);
    this.down = this.down.bind(this);
    
    this.attributes = pick(this.props, 'id', {}) 
  }
  
  /**
   * Hook before component updates
   * @param  {Object} nextProps
   * @param  {Object} nextState
   * @return {void}
   */
  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value && typeof this.props.onUpdate === 'function') {
      this.props.onUpdate.call(this, this.state.value);
    }
    return true
  }
  
  /**
   * Reduces quantity by one
   * @return {void}
   */
  down() {
    if (this.state.value > this.props.min) {
      this.setState({
        value: this.state.value - 1
      })
    }
  }
  
  /**
   * Increases quantity by one
   * @return {void}
   */
  up() {
    if (this.state.value < this.props.max || !this.props.max) {
      this.setState({
        value: parseInt(this.state.value) + 1
      })
    }
  }  
  
  /**
   * Retrieves quantity
   * @return {Number}
   */
  getValue() {
    return this.state.value
  }
  
  render() {    
    return (
      <div className={`number-spinner ${ this.props.className ? this.props.className : '' }`} { ...this.attributes }>
        <button className="btn-down" onClick={this.down} title="Decrease Quantity">-</button>
        <input name="product-quantity" title="product-quantity" value={this.state.value} readOnly className={ this.props.inputClassName ? this.props.inputClassName : '' }/>
        <button className="btn-up" onClick={this.up} title="Increase Quantity">+</button>
      </div>
    )
  }
}

export default NumberSpinner

NumberSpinner.defaultProps = {
  min: 1,
  max: null,
}
